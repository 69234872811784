import { Popover, Tag } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaBan } from "react-icons/fa";
import moment from "moment";
import { billingApi, usersApi } from "../../../api";
import {
  ILicence,
  IResponseError,
  IUser,
  LicenceColor,
  ModalType,
  PaymentStatus,
} from "../../../data-access";
import { RootState } from "../../../store";
import { storeAuthActions } from "../../../store/slices/auth";
import { storeBillingActions } from "../../../store/slices/billing";
import { storeModalsActions } from "../../../store/slices/modals";
import { DataTable, FormButton, FormSelect, Panel } from "../../../ui";
import { paginate } from "../../../ui/data-table/data-table-utils";
import {
  capitalize,
  handleFailedRequest,
  notify,
  updateExtensionCookies,
} from "../../../util";
import "./billing-licence.scss";

export const BillingLicences = (): JSX.Element => {
  const availableLicences = useSelector(
    (state: RootState) => state.billing.licences
  );
  const teamMembers = useSelector((state: RootState) => state.team.members);
  const dispatch = useDispatch();

  const unmanageablePaymentStatuses = ["failed", "not_paid"];
  const membersSelectOptions = useMemo(
    () =>
      teamMembers.map((member) => ({
        value: member.id,
        label: member.full_name ?? member.email,
      })),
    [teamMembers]
  );

  const [isProcessing, setIsProcessing] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const TABLE_COLUMNS = [
    { displayName: "Plan" },
    { displayName: "Licence ID" },
    { displayName: "Status" },
    { displayName: "Member" },
    { displayName: "AI Requests" },
    { displayName: "Date Start" },
    { displayName: "Next Subscription" },
    { displayName: "Action" },
  ];

  const handleCancel = (licenseId: string): void => {
    dispatch(
      storeModalsActions.open({
        type: ModalType.SubscriptionCancellation,
        props: { licenseId },
      })
    );
  };

  const openPaymentDetails = (url: string) => {
    window.open(url, "_blank");
  };

  const handlePay = (id: string): void => {
    setIsProcessing(true);

    billingApi
      .getPaymentLink(id)
      .then((response: { data: { payment_url: string } }) => {
        if (response.data.payment_url) {
          window.location.href = response.data.payment_url;
        } else {
          notify(
            "error",
            "Payment Error: Please contact our support team via the chat."
          );
        }
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleLicenseCustomerPortalLink = (id: string): void => {
    setIsProcessing(true);

    billingApi
      .getLicenseCustomerPortalLink(id)
      .then((response: { data: { customerPortalLink: string } }) => {
        openPaymentDetails(response.data.customerPortalLink);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const getPaymentStatusDisplay = (
    paymentStatus: PaymentStatus,
    licenceId: string,
    isStripe: boolean,
    customerPortalLink: string
  ): JSX.Element => {
    switch (paymentStatus) {
      case "paid":
        return isStripe ? (
          <div className="button-wrap">
            <FormButton
              theme="blue"
              disabled={isProcessing}
              onClick={() => handleLicenseCustomerPortalLink(licenceId)}
            >
              Details
            </FormButton>
          </div>
        ) : (
          <div className="button-wrap">
            <FormButton
              theme="red"
              disabled={isProcessing}
              onClick={() => handleCancel(licenceId)}
            >
              Cancel
            </FormButton>
          </div>
        );
      case "failed":
        return isStripe ? (
          <div className="button-wrap">
            <FormButton
              theme="blue"
              disabled={isProcessing}
              onClick={() => handleLicenseCustomerPortalLink(licenceId)}
            >
              Details
            </FormButton>
          </div>
        ) : (
          <div className="button-wrap">
            <FormButton
              theme="blue"
              disabled={isProcessing}
              onClick={() => handleCheckStatus(licenceId)}
            >
              Status
            </FormButton>
          </div>
        );
      case "past_due":
        return isStripe ? (
          <div className="button-wrap">
            <FormButton
              theme="blue"
              disabled={isProcessing}
              onClick={() => handleLicenseCustomerPortalLink(licenceId)}
            >
              Details
            </FormButton>
          </div>
        ) : (
          <FaBan />
        );
      case "not_paid":
        return (
          <div className="button-wrap">
            <FormButton
              theme="green"
              disabled={isProcessing}
              onClick={() => handlePay(licenceId)}
            >
              Pay
            </FormButton>
          </div>
        );
      default:
        return isStripe ? (
          <div className="button-wrap">
            <FormButton
              theme="blue"
              disabled={isProcessing}
              onClick={() => handleLicenseCustomerPortalLink(licenceId)}
            >
              Details
            </FormButton>
          </div>
        ) : (
          <FaBan />
        );
    }
  };

  const handleLicencesFetch = (): void => {
    setIsProcessing(true);

    billingApi
      .getLicences()
      .then((response: { data: ILicence[] }) => {
        dispatch(
          storeBillingActions.update({
            licences: response.data,
          })
        );
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => setIsProcessing(false));
  };

  const handleLicenceUnassign = (
    unassignFromId: string,
    licenceId: string,
    assignToId?: string,
    isReassign = false
  ): void => {
    setIsProcessing(true);

    usersApi
      .unAssignLicense(unassignFromId)
      .then((response: { data: IUser }) => {
        if (!isReassign) {
          notify("success", "License has been successfully unassigned.");
          updateExtensionCookies({ user: response.data });
        } else if (assignToId) {
          handleLicenceAssign(assignToId, licenceId);
        }
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleLicenceAssign = (userId: string, licenceId: string): void => {
    setIsProcessing(true);
    usersApi
      .assignLicense(userId, licenceId)
      .then((response: { data: IUser }) => {
        notify("success", "License has been successfully assigned.");
        updateExtensionCookies({ user: response.data });
        handleLicencesFetch();
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleLicenceHolderChange = (
    assignToUserId: string,
    licenceId: string
  ): void => {
    const selectedLicence = availableLicences.find(
      (license: ILicence) => license.id === licenceId
    );
    const currentHolderId = selectedLicence?.user?.id;

    if (!!currentHolderId) {
      handleLicenceUnassign(currentHolderId, licenceId, assignToUserId, true);
    } else {
      handleLicenceAssign(assignToUserId, licenceId);
    }
  };

  const getNextSubscriptionData = (licence: ILicence) => {
    if (licence.billing_plan_name === "Trial") {
      return (
        <Tag color="blue">
          {`Trial ends ${moment(licence.ends_at).format("MMM DD")}`}
        </Tag>
      );
    } else if (licence?.ends_at && licence?.payment_status !== "canceled") {
      return moment(licence.ends_at).format("DD MMMM YYYY");
    } else if (!licence?.ends_at) {
      return <FaBan />;
    } else {
      return (
        <Popover
          content={`Subscription ends: ${moment(licence.ends_at).format(
            "DD MMMM YYYY"
          )}`}
        >
          <Tag>Stopped</Tag>
        </Popover>
      );
    }
  };

  const getTableData = () => {
    return availableLicences.map((licence) => ({
      billing_plan_name: licence.billing_plan_name,
      licence: licence.id,
      subscription: licence.payment_status ? (
        <Tag
          color={
            LicenceColor[licence.payment_status as keyof typeof LicenceColor]
          }
        >
          {licence.payment_status === "canceled"
            ? "Paid"
            : capitalize(licence.payment_status.replace("_", " "))}
        </Tag>
      ) : null,
      member: unmanageablePaymentStatuses.includes(licence.payment_status) ? (
        licence?.user?.id ? (
          licence.user.email
        ) : (
          <FaBan />
        )
      ) : (
        <FormSelect
          value={licence?.user?.id || null}
          placeholder="None"
          disabled={unmanageablePaymentStatuses.includes(
            licence.payment_status
          )}
          options={membersSelectOptions}
          onChange={(userId) => handleLicenceHolderChange(userId, licence.id)}
        />
      ),
      ai_requests: `${licence.used_ai_requests}/${licence.given_ai_requests}`,
      starts_at: licence?.starts_at ? (
        moment(licence.starts_at).format("DD MMMM YYYY")
      ) : (
        <FaBan />
      ),
      ends_at: getNextSubscriptionData(licence),
      payment_status: getPaymentStatusDisplay(
        licence.payment_status,
        licence.id,
        licence.is_stripe,
        licence.customer_portal_link
      ),
    }));
  };

  const handleCheckStatus = (id: string): void => {
    setIsProcessing(true);
    billingApi
      .checkBillingLicenceStatus(id)
      .then((response: { error: string; message: string }) => {
        dispatch(
          storeModalsActions.open({
            type: ModalType.SubscriptionCheckStatus,
            props: {
              failReason: response.message,
              licenseId: id,
            },
          })
        );
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handlePagination = (perPage: number, requestedPage: number): void => {
    const paginateData = paginate(availableLicences, perPage, requestedPage);
    const actualPage =
      paginateData.length > 0
        ? requestedPage
        : Math.ceil(availableLicences.length / perPage);
    setPerPage(perPage);
    setCurrentPage(actualPage);
  };

  useEffect(() => {
    handleLicencesFetch();
  }, []);

  useEffect(() => {
    setIsProcessing(true);
    usersApi
      .getMe()
      .then((response: { data: IUser }) => {
        const userData = { user: response.data };
        dispatch(storeAuthActions.setUserData(userData));
        dispatch(
          storeBillingActions.update({ discount: response.data?.promocode })
        );
        updateExtensionCookies(userData);
      })
      .catch()
      .finally(() => setIsProcessing(false));
  }, [dispatch, availableLicences]);

  return (
    <>
      <Panel title="Licences" className="billing-licences">
        <DataTable
          isLoading={isProcessing}
          columns={TABLE_COLUMNS}
          currentPage={currentPage}
          perPage={perPage}
          data={getTableData()}
          onPagination={handlePagination}
        />
      </Panel>
    </>
  );
};
