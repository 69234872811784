import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import { Spinner } from '../spinner';
import './panel.scss';

interface IPanelProps {
  title?: string;
  className?: string;
  children: string | ReactNode;
  nodeInTitleRow?: null | string | ReactNode;
  isLoading?: boolean;
}

const baseClass = 'panel';

export const Panel = ({ title, children, className = '', nodeInTitleRow = null, isLoading = false }: IPanelProps): JSX.Element => {
  return (
    <div className={`${baseClass} ${className}`}>
      {(title || nodeInTitleRow) && (
        <div className={`${baseClass}__heading`}>
          {title && <h1 className="name">{title}</h1>}
          {nodeInTitleRow ? nodeInTitleRow : null}
        </div>
      )}
      <div className={`${baseClass}__content`}>
        {isLoading && <Spinner />}
        {!isLoading && children}
      </div>
    </div>
  );
}

Panel.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.any.isRequired,
  nodeInTitleRow: PropTypes.any,
};