import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usersApi } from '../../api';
import {
  AnalyticsPeriod,
  IAnalyticsResponse,
  IResponseError,
  ITeamMemberRecord,
  ITeamMembersResponse,
  ProfileFilterCriteriaType,
  IProfilesGeneralStatistics,
  IProfilesAnalyticsResponse
} from '../../data-access';
import { useUser } from '../../hooks';
import { RootState } from '../../store';
import { storeAnalyticsActions } from '../../store/slices/analytics';
import { storeTeamActions } from '../../store/slices/team';
import { FormSelect, Panel, BarChart } from '../../ui';
import { capitalize, handleFailedRequest } from '../../util';
import { GeneralStatistics } from './general-statistics';
import { ProfileStatistics } from './profile-statistics';
import './analytics.scss';

const periodFilter = [
  { value: 'current_week', label: 'Current Week' },
  { value: 'last_week', label: 'Last Week' },
  { value: 'current_month', label: 'Current Month' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'last_3_months', label: 'Last 3 Months' },
  { value: 'last_year', label: ' Last Year' },
];

const profileStatsFilters = [{
  name: 'current_week',
  label: 'Current Week'
}, {
  name: 'last_week',
  label: 'Last Week'
}, {
  name: 'current_month',
  label: 'Current Month'
}, {
  name: 'last_month',
  label: 'Last Month'
}, {
  name: 'last_3_months',
  label: '3 Months'
}, {
  name: 'last_6_months',
  label: '6 Months'
}, {
  name: 'current_year',
  label: 'Current Year'
}, {
  name: '',
  label: 'All Time'
}];

const baseClass = 'analytics';

export const Analytics = (): JSX.Element => {
  const { currentUser } = useUser();

  const teamMembers = useSelector((state: RootState) => state.team.members);
  const filterPeriod = useSelector((state: RootState) => state.analytics.filter.period);
  const filterUserId = useSelector((state: RootState) => state.analytics.filter.userId);

  const temMemberFilterOptions = useMemo(() => teamMembers.map((member: ITeamMemberRecord) => ({
    value: member.id,
    label: member.full_name ?? capitalize(member.email.substring(0, member.email.indexOf('@'))),
  })), [teamMembers]);

  const [isProcessingRequest, setIsProcessingRequest] = useState<boolean>(false);
  const [isFetchingMembers, setIsFetchingMembers] = useState<boolean>(false);
  const [isFetchingJobScoreStats, setIsFetchingJobScoreStats] = useState<boolean>(true);
  const [currentUserFilter, setCurrentUserFilter] = useState<string>(filterUserId ?? String(currentUser?.id));
  const [currentPeriodFilter, setCurrentPeriodFilter] = useState<string>(filterPeriod || periodFilter[0]?.value);
  const [analytics, setAnalytics] = useState<IAnalyticsResponse>({
    total: {
      ai_answers: 0,
      ai_proposals:0,
      bids: 0,
      opened_jobs: 0,
      replies: 0,
      reply_rate: 0,
      sync_with_crms: 0,
    }
  } as IAnalyticsResponse);
  const [currentProfileStatsCriteria, setCurrentProfileStatsCriteria] = useState<ProfileFilterCriteriaType>(profileStatsFilters[0].name as ProfileFilterCriteriaType);
  const [profilesStats, setProfilesStats] = useState<IProfilesGeneralStatistics>({} as IProfilesGeneralStatistics);

  const dispatch = useDispatch();

  const handlePeriodFilterChange = (period: string): void => {
    setCurrentPeriodFilter(period);
    dispatch(storeAnalyticsActions.setPeriod({ period }));
  }

  const handleUsersFilterChange = (userId: string): void => {
    setCurrentUserFilter(userId);
    dispatch(storeAnalyticsActions.setUserId({ userId }));
  }

  useEffect(() => {
    if (teamMembers.length === 0) {
      setIsFetchingMembers(true);
      usersApi.getTeamMembers()
        .then((response: ITeamMembersResponse) => {
          dispatch(storeTeamActions.setMembers(response.data));
        })
        .catch((error: IResponseError) => {
          handleFailedRequest(error.status, error.text);
        })
        .finally(() => setIsFetchingMembers(false));
    }

    setTimeout(() => {
      setIsFetchingJobScoreStats(false);
    }, 1500);
  }, [dispatch, teamMembers.length]);

  useEffect(() => {
    setIsProcessingRequest(true);

    usersApi.getUserAnalytics({
      id: currentUserFilter,
      period: currentPeriodFilter as AnalyticsPeriod
    })
      .then((response: { data: IAnalyticsResponse })=> {
        setAnalytics(response.data);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingRequest(false));

  }, [currentPeriodFilter, currentUserFilter]);

  useEffect(() => {
    setIsFetchingJobScoreStats(true);
    usersApi.getAnalyticsForProfiles(currentProfileStatsCriteria)
      .then((response: IProfilesAnalyticsResponse) => setProfilesStats(response.data))
      .catch((error: IResponseError) => handleFailedRequest(error.status, error.text))
      .finally(() => setIsFetchingJobScoreStats(false));
  }, [currentProfileStatsCriteria]);

  const handleProfileStatsFilterClick = (criteria: ProfileFilterCriteriaType): void => {
    if (isFetchingJobScoreStats) return;

    setCurrentProfileStatsCriteria(criteria);
  }

  return (
    <div className={baseClass}>
      <Panel title="Upwork Profile Stats" className={`profile-stats-panel ${isFetchingJobScoreStats ? 'is-loading' : ''}`} nodeInTitleRow={(
        <ul className={`${baseClass}__profile-stats-filters`}>
          {profileStatsFilters.map((filter) => (
            <li key={filter.name} className={`${baseClass}__profile-stats-filters ${currentProfileStatsCriteria === filter.name ? 'active' : ''}`} onClick={() => handleProfileStatsFilterClick(filter.name as ProfileFilterCriteriaType)}>{filter.label}</li>
          ))}
        </ul>
      )}>
        <ProfileStatistics isLoading={isFetchingJobScoreStats} data={profilesStats} filterPeriod={currentProfileStatsCriteria} />
      </Panel>
      <Panel title="User Statistics" className={`${baseClass}-panel`} nodeInTitleRow={(
        <div className={`${baseClass}__filters`}>
          <FormSelect
            value={currentPeriodFilter}
            disabled={isProcessingRequest || isFetchingMembers}
            options={periodFilter}
            onChange={handlePeriodFilterChange}
          />
          <FormSelect
            value={currentUserFilter}
            disabled={isProcessingRequest}
            placeholder="Please select a user"
            options={[
              {
                label: 'All',
                value: ''
              },
              ...temMemberFilterOptions
            ]}
            onChange={handleUsersFilterChange}
          />
        </div>
      )}>
        <GeneralStatistics isLoading={isProcessingRequest} stats={analytics?.total} />
      </Panel>
      <Panel className="chart-panel" isLoading={isProcessingRequest}>
        {!isProcessingRequest && (
          <BarChart
            isLoading={isProcessingRequest}
            labels={analytics?.graph?.labels}
            datasets={
              [
                {
                  label: 'Open Job',
                  data: analytics?.graph?.datasets?.opened_jobs || [],
                  backgroundColor: '#57cc99',
                },
                {
                  label: 'Bid',
                  data: analytics?.graph?.datasets?.bids || [],
                  backgroundColor: '#80ed99',
                },
                {
                  label: 'Reply',
                  data: analytics?.graph?.datasets?.replies || [],
                  backgroundColor: '#c7f9cc',
                },
              ]
            }
          />
        )}
      </Panel>
    </div>
  );
}

export default Analytics;