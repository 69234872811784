import { baseApiPath, deleteEntity, get, post } from "./base-api";
import {
  ICustomerPortalLink,
  ILicence,
  IPaymentHistoryRecord,
  IPlan,
  IPromoCodeAddResponse,
} from "../data-access";

const checkBillingLicenceStatus = (id: string): Promise<any> => {
  return get(`${baseApiPath}/api/billing/licenses/${id}/check-status`);
};

const createBillingLicence = (): Promise<{ data: ILicence }> => {
  return post(`${baseApiPath}/api/billing/licenses/create`, {});
};

const renewBillingLicence = (id: string): Promise<{ data: ILicence }> => {
  return post(`${baseApiPath}/api/billing/licenses/${id}/recreate`, {});
};

const deleteBillingLicence = (
  id: string
): Promise<{ message: string; error: string }> => {
  return deleteEntity(`${baseApiPath}/api/billing/licenses/${id}/delete`);
};

const getLicences = (): Promise<{ data: ILicence[] }> => {
  return get(`${baseApiPath}/api/billing/licenses`);
};

const getPaymentsHistory = (): Promise<{ data: IPaymentHistoryRecord[] }> => {
  return get(`${baseApiPath}/api/billing/payments`);
};

const getPaymentLink = (
  licenceId: string
): Promise<{ data: { payment_url: string } }> => {
  return get(`${baseApiPath}/api/billing/licenses/${licenceId}/payment-url`);
};

const getPlans = (): Promise<{ data: IPlan[] }> => {
  return get(`${baseApiPath}/api/billing/plans`);
};

const getLicenseCustomerPortalLink = (
  id: string
): Promise<{ data: ICustomerPortalLink }> => {
  return get(`${baseApiPath}/api/billing/licenses/${id}/customer-portal`);
};

const selectPlan = (id: number): Promise<{ data: IPlan[] }> => {
  return post(`${baseApiPath}/api/billing/plans/${id}/select`, {});
};

const purchaseAdditionalRequests = (
  amount: number,
  licenseId: string
): Promise<{ data: { paymentLink: string } }> => {
  return post(`${baseApiPath}/api/chat-gpt/addAiRequests`, {
    amount,
    licenseId,
  });
};

const applyPromoCode = (code: string): Promise<IPromoCodeAddResponse> => {
  return post(`${baseApiPath}/api/billing/promocodes/add`, { code });
};

export const billingApi = {
  createBillingLicence,
  deleteBillingLicence,
  renewBillingLicence,
  getLicences,
  getPaymentsHistory,
  getPaymentLink,
  getPlans,
  selectPlan,
  checkBillingLicenceStatus,
  purchaseAdditionalRequests,
  applyPromoCode,
  getLicenseCustomerPortalLink,
};
