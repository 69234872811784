import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  FaUserFriends,
  FaChartBar,
  FaCogs,
  FaTools,
  FaHireAHelper,
  FaBookOpen,
  FaCreditCard,
  FaMap,
  FaVideo,
  FaBullhorn,
} from "react-icons/fa";
import { BrandLogo } from "../brand-logo";
import { appConfig } from "../../app-config";
import { APP_ROUTES } from "../../data-access";
import { useMobileMenu, useUser } from "../../hooks";
import "./sidebar.scss";

export const SidebarMenu = (): JSX.Element => {
  const { hasAdminRights } = useUser();
  const { isMenuOpened, toggleBurgerMenu } = useMobileMenu();

  const checkedRelease = useSelector(
    (state: { generic: { [key: string]: string } }) =>
      state.generic.checkedRelease
  );
  const isNewReleaseSeen = checkedRelease !== appConfig.version;

  const handleLinkClick = (): void => {
    if (isMenuOpened) {
      toggleBurgerMenu();
    }
  };

  return (
    <nav className="sidebar">
      <BrandLogo allowNavigation={false} />
      <h1 className="title">Account</h1>
      <NavLink to={APP_ROUTES.dashboard} onClick={handleLinkClick}>
        <FaChartBar className="icon" />
        Dashboard
      </NavLink>
      <NavLink to={APP_ROUTES.team} onClick={handleLinkClick}>
        <FaUserFriends className="icon" />
        Team
      </NavLink>
      {hasAdminRights && (
        <NavLink
          to={APP_ROUTES.billing}
          state={{ allowedTransition: true }}
          onClick={handleLinkClick}
        >
          <FaCreditCard className="icon" />
          Billing
        </NavLink>
      )}
      <NavLink to={APP_ROUTES.settings} onClick={handleLinkClick}>
        <FaCogs className="icon" />
        Settings
      </NavLink>
      <h1 className="title">Features</h1>
      <NavLink to={APP_ROUTES.toolsAndApps} onClick={handleLinkClick}>
        <FaTools className="icon" />
        Tools & Apps
      </NavLink>

      <h1 className="title">More</h1>
      <NavLink
        to="https://help.upwex.io/"
        target="_blank"
        onClick={handleLinkClick}
      >
        <FaHireAHelper className="icon" />
        Help Center
      </NavLink>
      <NavLink
        to="https://www.youtube.com/watch?v=80aZR069-QE"
        target="_blank"
        onClick={handleLinkClick}
      >
        <FaVideo className="icon" />
        Get Started Guide
      </NavLink>
      <NavLink to={APP_ROUTES.releaseNotes} onClick={handleLinkClick}>
        <FaBookOpen className="icon" />
        Release Notes
        {isNewReleaseSeen && <span className="new-release-indicator" />}
      </NavLink>
      {appConfig.roadmap.isEnabled && (
        <>
          <NavLink to={APP_ROUTES.roadmap} onClick={handleLinkClick}>
            <FaMap className="icon" />
            Roadmap
          </NavLink>
          <NavLink to={APP_ROUTES.specialOffers} onClick={handleLinkClick}>
            <FaBullhorn className="icon" />
            Special Offers
          </NavLink>
        </>
      )}
    </nav>
  );
};

export default SidebarMenu;
