import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { APP_ROUTES } from "../../data-access";
import { useUser } from "../../hooks";
import { InformationBlock } from "../../ui";
import { AIPersonalKeyInfo } from "./ai-personal-key-info";
import { PipedriveForm } from "./pipedrive-form";
import { Tool } from "./tool";
import "./tools-and-apps.scss";

export const ToolsAndApps = (): JSX.Element => {
  const [isPipedriveLoading, setIsPipedriveLoading] = useState<
    boolean | undefined
  >(undefined);
  const { currentUser } = useUser();

  const isUserOnLitePlan = currentUser && currentUser?.license?.plan === "Lite";
  const isUserOnStripePlans =
    currentUser &&
    currentUser?.license?.is_stripe &&
    (currentUser?.license?.plan === "Starter" ||
      currentUser?.license?.plan === "Rising Talent");
  console.log("isUserOnLitePlan = ", isUserOnLitePlan);

  return (
    <div className="tools-and-apps">
      <h2 className="tools-and-apps__section-title">CRM</h2>
      {isUserOnLitePlan && (
        <InformationBlock>
          This functionality is not available for <b>Lite</b> plan. Please
          upgrade your{" "}
          <NavLink className="link" to={APP_ROUTES.plans}>
            plan
          </NavLink>{" "}
          to unlock it.
        </InformationBlock>
      )}
      {isUserOnStripePlans && (
        <InformationBlock>
          This functionality is not available for your plan. Please upgrade your{" "}
          <NavLink className="link" to={APP_ROUTES.plans}>
            plan
          </NavLink>{" "}
          to unlock it.
        </InformationBlock>
      )}
      {!isUserOnLitePlan && !isUserOnStripePlans && (
        <Tool
          isFetching={isPipedriveLoading}
          className="pipedrive"
          title="Pipedrive"
          picture="./pipedrive_logo.svg"
        >
          <PipedriveForm
            onRequest={(isLoading: boolean) => setIsPipedriveLoading(isLoading)}
          />
        </Tool>
      )}
      <h2 className="tools-and-apps__section-title">AI</h2>
      <Tool title="Open AI" showTooltip={true} picture="./chat_gpt_logo.svg">
        <AIPersonalKeyInfo />
      </Tool>
    </div>
  );
};

export default ToolsAndApps;
