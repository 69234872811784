import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './data-table-pagination.scss';

interface IDataTablePaginationProps {
  perPage: number;
  perPageOptions: number[];
  total: number;
  currentPage: number;
  onPagination: (perPage: number, requestedPage: number) => void;
}

const baseClassName = 'data-table-pagination';

export function DataTablePagination({ perPage, perPageOptions, currentPage, onPagination, total }: IDataTablePaginationProps): JSX.Element | null {
  const totalPages = Math.ceil(total / perPage);

  const isPerPageSelectorEnabled = total > perPageOptions[0];
  const isPageSelectorEnabled = totalPages > 1;

  if (!isPerPageSelectorEnabled && !isPageSelectorEnabled) {
    return null;
  }

  return (
    <div className={baseClassName}>
      {isPerPageSelectorEnabled && (
        <span className={`${baseClassName}__per-page-selector`}>
          Showing <select defaultValue={perPage} onChange={e => onPagination(+e.target.value, currentPage)}>
            {perPageOptions.map(recordsPerPage => <option key={`record-per-page-${recordsPerPage}`} value={recordsPerPage}>{recordsPerPage}</option>)}
          </select> of {total} entries
        </span>
      )}
      {isPageSelectorEnabled && (
        <div className={`${baseClassName}__page-selector`}>
          <button disabled={currentPage === 1} onClick={() => onPagination(perPage, currentPage - 1)}><LeftOutlined /></button>
          <button disabled={currentPage === totalPages} onClick={() => onPagination(perPage, currentPage + 1)}><RightOutlined /></button>
        </div>
      )}
    </div>
  );
}