import './ai-personal-info-key.scss';

const baseClass = 'ai-key-personal-info';

export const AIPersonalKeyInfo = (): JSX.Element => {
  const openIntercomChat = (): void => {
    if (!!(window as any)?.Intercom) {
      (window as any).Intercom('show');
    }
  }

  return (
    <div className={baseClass}>
      <p className={`${baseClass}__paragraph`}>If you have your own key, write to us in <b onClick={openIntercomChat}>chat</b> and we will add it for you.</p>
    </div>
  );
};