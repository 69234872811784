import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Routes, Route } from "react-router-dom";
import { usersApi } from "./api";
import { appConfig } from "./app-config";
import { APP_ROUTES, IUser } from "./data-access";
import {
  Auth,
  Team,
  Analytics,
  ToolsAndApps,
  ReleaseNotes,
  Plans,
  PrivateRoute,
  ProtectedRoute,
  Registration,
  ExtensionAuth,
  Login,
  Settings,
  PipedriveAuth,
  UpdateBillingDetailsForm,
  ChoosePaymentMethod,
  NothingFound,
  ExtensionSignOut,
  Billing,
  PaymentProcessing,
  Roadmap,
  FeatureRequest,
  Proposals,
  ModalsContainer,
  LiveChat,
} from "./feature";
import { useUser } from "./hooks";
import { storeAuthActions } from "./store/slices/auth";
import { updateExtensionCookies } from "./util";
import { SpecialOffers } from "./feature/special-offers";
import { NotificationManager } from "./feature/notification-manager";

const MINUTE_IN_MILLISECONDS = 60000;

function App(): JSX.Element {
  const { currentUser } = useUser();
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;
  const pluginCheckExcludedOnPages = [
    APP_ROUTES.paymentProcessing,
    APP_ROUTES.extensionGeneral,
    APP_ROUTES.extensionJobScoreDetails,
    APP_ROUTES.extensionUserProfile,
    APP_ROUTES.extensionAnalytics,
    APP_ROUTES.extensionDeals,
    APP_ROUTES.extensionCRM,
    APP_ROUTES.extensionPrompts,
    APP_ROUTES.extensionPromptCreate,
    APP_ROUTES.extensionPromptEdit,
    APP_ROUTES.extensionSettings,
  ];

  useEffect(() => {
    let getMeIntervalId: ReturnType<typeof setInterval> | string = "";

    if (currentUser && !pluginCheckExcludedOnPages.includes(currentPath)) {
      getMeIntervalId = setInterval(() => {
        usersApi.getMe().then((response: { data: IUser }) => {
          const userData = { user: response.data };
          dispatch(storeAuthActions.setUserData(userData));
          updateExtensionCookies(userData);
        });
      }, MINUTE_IN_MILLISECONDS);
    }

    return () => {
      getMeIntervalId && clearInterval(getMeIntervalId);
    };
  }, [currentUser]);

  return (
    <div className="main-container">
      <LiveChat />
      <NotificationManager />
      <ModalsContainer />
      <Routes>
        <Route
          path={APP_ROUTES.home}
          element={<Navigate to={APP_ROUTES.login} />}
        />
        <Route
          path={APP_ROUTES.extensionAuth}
          element={
            !currentUser ? (
              <ExtensionAuth />
            ) : (
              <Navigate to={APP_ROUTES.dashboard} />
            )
          }
        />
        <Route
          path={APP_ROUTES.login}
          element={
            <ProtectedRoute renderCondition={!currentUser}>
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path={APP_ROUTES.registration}
          element={
            <ProtectedRoute renderCondition={!currentUser}>
              <Registration />
            </ProtectedRoute>
          }
        />
        <Route
          path={APP_ROUTES.auth}
          element={
            <ProtectedRoute renderCondition={!currentUser}>
              <Auth />
            </ProtectedRoute>
          }
        />
        <Route
          path={APP_ROUTES.paymentProcessing}
          element={
            <ProtectedRoute renderCondition={!!currentUser}>
              <PaymentProcessing />
            </ProtectedRoute>
          }
        />
        <Route
          path={APP_ROUTES.settings}
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.team}
          element={
            <PrivateRoute>
              <Team />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.dashboard}
          element={
            <PrivateRoute>
              <Analytics />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.toolsAndApps}
          element={
            <PrivateRoute>
              <ToolsAndApps />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.releaseNotes}
          element={
            <PrivateRoute>
              <ReleaseNotes />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.billing}
          element={
            <PrivateRoute isForAdminOnly={true}>
              <Billing />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.updateBilling}
          element={
            <PrivateRoute>
              <UpdateBillingDetailsForm />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.choosePaymentMethod}
          element={
            <PrivateRoute>
              <ChoosePaymentMethod />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.plans}
          element={
            <PrivateRoute>
              <Plans />
            </PrivateRoute>
          }
        />
        <Route path={APP_ROUTES.pipedrive} element={<PipedriveAuth />} />
        <Route
          path={APP_ROUTES.extensionSignOut}
          element={
            <PrivateRoute>
              <ExtensionSignOut />
            </PrivateRoute>
          }
        />
        {appConfig.roadmap.isEnabled && (
          <>
            <Route
              path={APP_ROUTES.roadmap}
              element={
                <PrivateRoute>
                  <Roadmap />
                </PrivateRoute>
              }
            />
            <Route
              path={APP_ROUTES.specialOffers}
              element={
                <PrivateRoute>
                  <SpecialOffers />
                </PrivateRoute>
              }
            />
            <Route
              path={APP_ROUTES.featureRequest}
              element={
                <PrivateRoute>
                  <FeatureRequest />
                </PrivateRoute>
              }
            />
          </>
        )}
        <Route
          path={APP_ROUTES.proposals}
          element={
            <PrivateRoute>
              <Proposals />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NothingFound />} />
      </Routes>
    </div>
  );
}

export default App;
