import React, { ReactNode } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { Spinner } from '../../../ui';
import { GptInstructions } from '../gpt-instruction/gpt-instruction';
import './tool.scss';

interface IToolProps {
  title: string;
  picture?: string;
  children: ReactNode,
  className?: string;
  isFetching?: boolean;
  showTooltip?: boolean;
}

const gptInstruction = <GptInstructions/>;

export const Tool = ({ className = '', picture = '', title, isFetching = false, showTooltip = false, children }: IToolProps): JSX.Element => {
  return (
    <div className={`tool ${className} ${isFetching ? 'fetching' : ''}`}>
      {isFetching && <Spinner />}
      <div className="tool__image-wrap">
        {picture && <img className="tool__image" src={picture} alt={title} />}
      </div>
      <div className="tool__details">
        <h2 className="tool__name">
          {title}
          {showTooltip && (
            <Popover placement="topLeft" content={gptInstruction} trigger="hover">
              <FaInfoCircle />
            </Popover>
          )}
        </h2>
        {children}
      </div>
    </div>
  );
}

Tool.propTypes = {
  picture: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  showTooltip: PropTypes.bool,
  isFetching: PropTypes.bool,
};