// enums
export enum PricingPlan {
  Trial = "Trial",
  Starter = "Starter",
  Lite = "Lite",
  Year = "Year",
  TopRatedPlus = "Top Rated Plus",
}

export enum PaymentMethod {
  SEPA = "SEPA Payment",
  CREDIT = "Credit Card",
}

export enum LicenceColor {
  paid = "green",
  failed = "red",
  not_paid = "blue",
  trial = "geekblue",
  canceled = "green",
  past_due = "red",
}

export enum RequestFeatureFilterCriteria {
  recent = "recent",
  popular = "popular",
}

export enum UserRoleColor {
  owner = "lime",
  admin = "purple",
  member = "geekblue",
}

export interface IRequestedFeature {
  title: string;
  description: string;
  status: string;
  votes: number;
}

export type RequestedFeatureStatusTitle =
  | "Under Consideration"
  | "Up Next"
  | "In Progress"
  | "Shipped";

export enum RequestedFeaturesStatusIndicatorColor {
  under_consideration = "orange",
  up_next = "green",
  in_progress = "blue",
  shipped = "red",
}

// interfaces
export interface IResponseError {
  status: number;
  text: string;
  reason?: string;
  details?: { [key: string]: string[] };
}

export interface ICheckAccessCodeParams {
  email: string;
  access_code: string;
}

export type UserStatus = "owner" | "admin" | "member";

export interface IUser {
  id: string;
  email: string;
  team_id: string;
  invited_by: string | null;
  paid_user: boolean;
  can_use_pipedrive: boolean;
  role: UserStatus;
  full_name: string;
  picture: string;
  is_verified: boolean;
  show_plashka: boolean;
  sync_start_date: string | null;
  chat_hash: string;
  trial_expires_at?: string;
  active_licenses_count: number;
  domain: string;
  plan: BillingPlans;
  teammates: number;
  teammates_paid: number;
  trial_date_start: string;
  trial_date_end: string;
  plan_start_date: string;
  plan_expire_date: string;
  license: {
    id: string;
    used_ai_requests: number;
    given_ai_requests: number;
    plan: BillingPlans;
    payment_status: string;
    is_stripe: boolean;
  } | null;
  pipedrive_app: "public" | "private_prod" | "private_dev";
  promocode: IDiscount | null;
}

export type ProfileFilterCriteriaType =
  | "current_week"
  | "last_week"
  | "current_month"
  | "last_month"
  | "3_months"
  | "6_months"
  | "current_year"
  | "all_time";

export interface IRegisterParams {
  email: string;
  picture?: string;
  full_name?: string;
}

export interface IRefreshTokenParams {
  refresh_token: string;
}

export interface IRefreshTokenResponse {
  access_token: string;
  access_token_expires_at: string;
}

export interface IAuthResponse extends IRefreshTokenResponse {
  refresh_token: string;
  refresh_token_expires_at: string | null;
  user: IUser;
}

export interface ITeamMemberRecord {
  email: string;
  full_name: string | null;
  id: string;
  invited_by: string | null;
  is_verified: boolean;
  paid_user: boolean;
  picture: string | null;
  role: UserStatus;
  sync_start_date: string | null;
  team_id: string;
  license: {
    id: string;
    used_ai_requests: number;
    given_ai_requests: number;
    payment_status: string;
  } | null;
  active_licenses_count: number;
  chat_hash: string;
  domain: string;
  plan: BillingPlans;
  teammates: number;
  teammates_paid: number;
  trial_expires_at?: string;
}

export interface ITeamMembersResponse {
  data: ITeamMemberRecord[];
}

export interface IGetPipedriveConnectionResponse {
  data: {
    is_connected: boolean;
  };
}

export interface ICrmSettings {
  auto_create_company: boolean;
  deal_name?: string;
  create_deal: boolean;
  create_all_notes_in_deal: boolean;
  create_lead: boolean;
  create_notes_for_job: boolean;
  create_notes_for_bid: boolean;
  create_notes_for_all_messages: boolean;
  sync_start_date: string;
  ai_model: string;
}

export interface IAISettings {
  automatic_job_ai_assistant: boolean;
}

export interface IGetSettingsResponse {
  data: {
    crm: ICrmSettings;
    job_assistant: IAISettings;
  };
}

export interface IAuthorizedPipedrive {
  access_code: string;
  state?: string;
}

export interface IAnalytics {
  ai_answers: number;
  ai_proposals: number;
  bids: number;
  opened_jobs: number;
  replies: number;
  reply_rate: number;
  sync_with_crms: number;
  direct_messages: number;
  invites: number;
  used_connects: number;
}

export interface IAnalyticsResponse {
  graph: {
    datasets: {
      opened_jobs: number[];
      bids: number[];
      replies: number[];
      ai_proposals: number[];
      ai_answers: number[];
      sync_with_crms: number[];
      reply_rate: number[];
      direct_messages: number[];
      invites: number[];
      used_connects: number[];
    };
    labels: string[];
  };
  total: IAnalytics;
}

export interface IPlan {
  created_at: string;
  currency: string;
  description: string[];
  id: number;
  is_archived: boolean;
  is_selected: boolean;
  period_name: string;
  is_trial: null | boolean;
  name: string;
  price: string;
  price_discounted?: string;
  updated_at: string;
}

export interface ICustomerPortalLink {
  customerPortalLink: string;
}

export type PaymentStatus =
  | "paid"
  | "not_paid"
  | "failed"
  | "trial"
  | "past_due"
  | "canceled";

export type OrderStatus = "processing" | "failed" | "approved";

export type BillingPlans =
  | "Starter"
  | "Trial"
  | "Lite"
  | "Year"
  | "Rising Talent";

export interface ILicence {
  billing_plan_name: BillingPlans;
  ends_at: string;
  id: string;
  customer_portal_link: string;
  is_stripe: boolean;
  is_subscribed: boolean;
  payment_status: PaymentStatus;
  starts_at: string;
  user: {
    id: string;
    email: string;
  } | null;
  used_ai_requests: number;
  given_ai_requests: number;
}

export interface IPaymentHistoryRecord {
  id: string;
  order_time: string;
  billing_plan_name: BillingPlans;
  billing_license_id: string;
  status: "paid" | "failed";
  stripe_details_url: string;
  masked_card: string;
  amount: string;
  currency: string;
}

export interface IJobScoreSettings {
  payment_verification: boolean;
  payment_verification_min: string;
  payment_verification_mid: string;
  payment_verification_max: string;
  rating: boolean;
  rating_min: string;
  rating_mid: string;
  rating_max: string;
  total_reviews: boolean;
  total_reviews_min: string;
  total_reviews_mid: string;
  total_reviews_max: string;
  hire_rate: boolean;
  hire_rate_min: string;
  hire_rate_mid: string;
  hire_rate_max: string;
}

export interface IProfileStats {
  upwex_user_id: string;
  email: string;
  bids: number;
  invites: number;
  direct_messages: number;
  replies: number;
  used_connects: number;
  reply_rate: number;
  upwork_user: {
    id: number;
    username: string;
    user_id: string;
    portrait: {
      portrait: string;
      bigPortrait: string;
      portrait500: string;
      smallPortrait: string;
    };
    email: string;
  };
}

export interface IProfilesGeneralStatistics {
  profiles: IProfileStats[];
  total: {
    bids: number;
    invites: number;
    direct_messages: number;
    replies: number;
    used_connects: number;
    reply_rate: number;
  };
}

export interface IProfilesAnalyticsResponse {
  data: IProfilesGeneralStatistics;
}

export interface IDiscount {
  code: string;
  discount_amount: number;
  expire_at: string;
}

export interface IPromoCodeAddResponse {
  data: IDiscount;
}

interface IProposalLink {
  url: string | null;
  label: string;
  active: boolean;
}

export interface IProposal {
  job_title: string;
  job_id: string;
  proposal_id: string;
  used_connects: number;
  is_reply: boolean;
}

export interface IGetProposalsResponse {
  data: IProposal[];
  user_data: {
    upwex_user_email: string;
    upwork_user_name: string;
  };
  pagination: {
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: IProposalLink[];
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
  };
  requestId: number;
}

export type AnalyticsPeriod =
  | "current_week"
  | "last_week"
  | "current_month"
  | "last_month"
  | "last_3_months"
  | "last_year";

export type FieldsMappingTabs = "contact" | "lead" | "company";

export enum SUCCESS_MESSAGES {
  SIGN_UP = "Registration was successful. Please check your mail.",
}

export enum VALIDATION_MESSAGES {
  INVALID_EMAIL = "Oops! Invalid email address",
}

export enum ERROR_MESSAGES {
  GENERIC = "Something went wrong...Please try again later.",
  ACCESS_CODE_EXPIRED = "Access_code is expired. Please log in again.",
  AUTH_FAIL = "There is an active session already established for this device. Make sure to logout from Chrome extension to proceed.",
  FETCH_PIPEDRIVE_FAIL = "Failed to fetch pipedrive connection status.",
  SESSION_ALREADY_SEIZED = "Auth token is already in use.",
}

export enum ColumnAlign {
  CENTER = "center",
  LEFT = "left",
  RIGHT = "right",
  JUSTIFY = "justify",
  CHAR = "char",
}

export enum ModalType {
  PurchaseLicences = "purchaseLicences",
  Discounts = "discounts",
  InviteTeamMembers = "inviteTeamMembers",
  Welcome = "welcome",
  BuyExtraRequests = "buyExtraRequests",
  SubscriptionCheckStatus = "subscriptionCheckStatus",
  SubscriptionCancellation = "subscriptionCancellation",
}
