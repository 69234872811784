import './gpt-instruction.scss';

const baseClass = 'gpt';

export const  GptInstructions = (): JSX.Element => {
  return (
    <div className={`${baseClass} column`}>
      <h3 className={`${baseClass}__title`}>What is this? How does it work?</h3>
      <p className={`${baseClass}__description`}>
        If you have a paid <a href="https://platform.openai.com/" target="_blank" rel="noreferrer">OpenAI</a> key, you can add it to our service.
        In this case, the balance of AI requests will always be controlled
        on your side for the current license(only the Starter plan).
      </p>
      <h3 className={`${baseClass}__title`}>How to do it?</h3>
      <p className={`${baseClass}__description`}>You can read about it <a href="https://help.upwex.io/en/articles/7321094-add-openai-key" target="_blank" rel="noreferrer">here.</a></p>
    </div>
  );
}