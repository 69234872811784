import { useEffect, useRef, useState } from "react";
import {
  DownOutlined,
  UpOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Empty } from "antd";
import { ColumnAlign } from "../../data-access";
import { useMobileDevice } from "../../hooks";
import { Spinner } from "..";
import { DataTablePagination } from "./data-table-pagination";
import { paginate } from "./data-table-utils";
import "./data-table.scss";

const baseClassName = "data-table";

interface IDataTableProps {
  columns: {
    displayName: string;
    field?: string;
    sortable?: boolean;
    textAlign?: ColumnAlign;
  }[];
  data?: any[];
  currentPage?: number;
  perPage?: number;
  perPageOptions?: number[];
  isLoading?: boolean;
  totals?: {
    label: string;
    value?: number | string;
    colspan?: number;
    skipOnMobile?: boolean;
  }[];
  tooltipToColumn?: { [key: number]: JSX.Element };
  totalRecords?: number;
  customPagination?: boolean;
  onPagination: (perPage: number, requestedPage: number) => void;
  onSorting?: (sorting: { field: string; order: "asc" | "desc" }) => void;
}

const getTextAlignment = (align: ColumnAlign): string => {
  switch (align) {
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return "center";
  }
};

export function DataTable({
  columns,
  data = [],
  totalRecords,
  currentPage = 1,
  perPage = 10,
  isLoading = false,
  perPageOptions = [10, 20, 50],
  customPagination = false, // table paginated entities unless it is API based  pagination
  totals,
  tooltipToColumn,
  onPagination,
  onSorting,
}: IDataTableProps): JSX.Element {
  const [extendedRows, setExtendedRows] = useState<number[]>([]);
  const [columnsSortingState, setColumnsSortingState] = useState<{
    [key: string]: string;
  }>({});

  const isMobileResolution = useMobileDevice();

  const prevPerPageRef = useRef<number>(perPage);

  const handleRowExtend = (index: number): void => {
    if (extendedRows.includes(index)) {
      const processedRows = extendedRows.filter(
        (rowIndex) => rowIndex !== index
      );
      setExtendedRows(processedRows);
    } else {
      setExtendedRows([...extendedRows, index]);
    }
  };

  useEffect(() => {
    setExtendedRows([]);
  }, [currentPage]);

  useEffect(() => {
    if (prevPerPageRef.current !== perPage) {
      prevPerPageRef.current = perPage;
    }
  }, [perPage]);

  const totalEntityNumber = totalRecords || data.length;
  const dataSource = !customPagination
    ? paginate(data, perPage, currentPage)
    : data;

  const performColumnSorting = (field: string, order: "asc" | "desc"): void => {
    setColumnsSortingState({
      ...columnsSortingState,
      [field]: order,
    });
    if (onSorting) {
      onSorting({ field, order });
    }
  };

  return (
    <div className={baseClassName}>
      {isLoading && (
        <div className={`${baseClassName}__overlay`}>
          <Spinner />
        </div>
      )}
      <table className={`${baseClassName}__table`}>
        <thead className={`${baseClassName}__heading`}>
          <tr>
            {columns.map((column, index) => (
              <th
                key={`table-th-${index}`}
                align={column.textAlign ?? ColumnAlign.CENTER}
              >
                <div
                  className="table-th-column-name"
                  style={{
                    justifyContent: getTextAlignment(
                      column.textAlign ?? ColumnAlign.CENTER
                    ),
                  }}
                >
                  {column.displayName}{" "}
                  {tooltipToColumn ? tooltipToColumn[index as any] : ""}
                  {column.sortable && (
                    <div className="sorting-controls">
                      <CaretUpOutlined
                        className={
                          column?.field &&
                          columnsSortingState[column.field] === "asc"
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          column?.field &&
                          performColumnSorting(column.field, "asc")
                        }
                      />
                      <CaretDownOutlined
                        className={
                          column?.field &&
                          columnsSortingState[column.field] === "desc"
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          column?.field &&
                          performColumnSorting(column.field, "desc")
                        }
                      />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSource.length === 0 && (
            <tr className="no-records">
              <td colSpan={columns.length}>
                <Empty />
              </td>
            </tr>
          )}
          {dataSource.length > 0 &&
            dataSource.map((record, index) => (
              <tr
                key={`table-tr-${index}`}
                className={`${
                  extendedRows.includes(index) ? "extended" : "collapsed"
                }`}
              >
                {isMobileResolution && (
                  <div onClick={() => handleRowExtend(index)}>
                    {!extendedRows.includes(index) ? (
                      <DownOutlined />
                    ) : (
                      <UpOutlined />
                    )}
                  </div>
                )}
                {Object.keys(record).map((key, index) => (
                  <td
                    key={`table-td-${key}`}
                    align={columns[index].textAlign ?? ColumnAlign.CENTER}
                  >
                    <span
                      className="value"
                      style={{
                        justifyContent: getTextAlignment(
                          columns[index].textAlign ?? ColumnAlign.CENTER
                        ),
                        textAlign: `${
                          columns[index].textAlign ?? ColumnAlign.CENTER
                        }` as any,
                      }}
                      data-th={columns[index].displayName}
                    >
                      {record[key]}
                    </span>
                  </td>
                ))}
              </tr>
            ))}
          {dataSource.length > 0 && totals && !isMobileResolution && (
            <tr className="totals">
              {totals.map((total) =>
                total?.skipOnMobile && isMobileResolution ? null : (
                  <td colSpan={total.colspan}>
                    {total.label === "Total" && !total.value ? (
                      total.label
                    ) : (
                      <span className="value" data-th={total.label}>
                        {total.label === "Total" &&
                          !isMobileResolution &&
                          `${total.label}: `}
                        {total.value}
                      </span>
                    )}
                  </td>
                )
              )}
            </tr>
          )}
        </tbody>
      </table>
      {data.length > 0 && (
        <DataTablePagination
          perPage={perPage}
          perPageOptions={perPageOptions}
          currentPage={currentPage}
          onPagination={onPagination}
          total={totalEntityNumber}
        />
      )}
    </div>
  );
}
